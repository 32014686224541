import {useContext, useState} from 'react';
import {startCase, find, set, reject, isEmpty} from 'lodash'
import SearchIcon from '@material-ui/icons/Search';
import {Query} from "react-apollo"
import { useQuery } from '@apollo/client';
import {useDispatch} from 'react-redux';
import { Button, Chip, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select, Input, InputAdornment, FormControl, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import {exerciseFilters as exerciseFiltersQuery, getExercises} from '../../graphql/exercises.graphql'
import {addExercises, editStage} from '../../actions/workout'
import {exercisePicker as style} from './style'
import ExerciseListItem from '../Workout/Exercise/ExerciseListItem'
import {useActions} from "../../../../helpers/useActions";
import {getWorkout} from "../../graphql/workout.graphql";
import {WorkoutContext} from "../../WorkoutContext";

const styles = {
  moreExrBtn: {
    width: '100%',
    margin: '10px 5px'
  },
};

const useStyles = makeStyles(theme=> ({
  paper: {
    margin: 0,
    width: '80%'
  },
}));

const ExercisePicker = props => {
  const dispatch = useDispatch();

  const actions = useActions({addExercises});
  props = {...props, ...actions};

  const [selectedExercises, setSelectedExercises] = useState([]);
  const [filterConditions, setFilterConditions] = useState({search: '', first: 40, skip: 0});
  const [page, setPage] = useState(1);

  const {first} = filterConditions;

  // get exercise filters
  const {loading, error, data} = useQuery(exerciseFiltersQuery);
  const exerciseFilters = data && data.exercise_filters

  if (loading) return 'Retrieving filters...';
  if (error) return `Error! ${error.message}`;
  const classes = useStyles();

  const {workout} = useContext(WorkoutContext);

  const renderHeader = () => {
    return (
      <View>
        <View style={style.headerContainer}>
          <Text style={style.headerTitle}>Add Exercises</Text>
          <FormControl>
            {/*<InputLabel htmlFor="add-exercise-search">Search Exercises</InputLabel>*/}
            <Input
              id="add-exercise-search"
              onChange={onSearchChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              } />
          </FormControl>
        </View>
        {renderFilters()}
        {renderSelectedExrs()}
      </View>
    )
  }

  const removeExercise = (exercise) => {
    const updatedExercise = selectedExercises.filter(exr => exr.name !== exercise.name);
    setSelectedExercises(updatedExercise);
  };

  const renderFilters = () => {
    const filters = exerciseFilters && omit(exerciseFilters, ['__typename'])
    return (
      <View style={style.filters}>
        {map(filters, (filter, key) => (
          <FormControl key={key}>
            <InputLabel htmlFor={key + '-select-label'}>{startCase(key)}</InputLabel>
            <Select
              style={{width: 185, paddingHorizontal: 5}}
              value={filterConditions[key] || ''}
              onChange={(e, i) => handleFilterChange(key, e.target.value)}
              inputProps={{
                name: key,
                id: key
              }}>
              {map(filter, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
            </Select>
          </FormControl>
        ))}
        {!isEmpty(filterConditions)
          ? <View style={{marginTop: 10}}>
            <Button
              color="primary"
              onClick={() => setFilterConditions({search: '', first: 20, skip: 0})}
            >
              Clear
            </Button>
          </View>
          : null
        }
      </View>
    )
  }

  const renderSelectedExrs = () =>
    <View style={style.selectedExrs}>
      {selectedExercises.map((exercise, index) =>
        <View key={exercise.id} style={{marginHorizontal: 3}}>
          <Chip
            onDelete={() => removeExercise(exercise)}
            label={exercise.name}/>
        </View>
      )}
    </View>

  const addExerciseAndClose = () => {
    props.addExercises(workout.id, selectedExercises);
    props.closeModal();
  };

  const selectExercise = (exercise) =>
    setSelectedExercises(selectedExercises => {
      if (find(selectedExercises, exercise))
        return reject(selectedExercises, exercise);
      else {
        return [...selectedExercises, exercise];
      }
    });

  const onSearchChange = ({target: {value}}) => {
    setFilterConditions({...filterConditions, search: value});
  }

  const handleFilterChange = (k, v) =>
    setFilterConditions({...filterConditions, [k]: v});

  {/*<Dialog*/}
  {/*  scroll="paper"*/}
  {/*  open*/}
  {/*  onClose={props.closeModal}*/}
  {/*  maxWidth="md"*/}
  {/*  classes={{*/}
  {/*    // container: classes.container,*/}
  {/*    paper: classes.paper*/}
  {/*  }}*/}
  {/*>*/}
  {/*</Dialog>*/}

  return (
    <React.Fragment>
      <DialogTitle disableTypography={true}>{renderHeader()}</DialogTitle>
      <DialogContent>
        <div className="exerciseFilters">
          <Query
            query={getExercises}
            variables={filterConditions}
          >
            {({loading, error, data, fetchMore}) => {
              console.log(data, "data")
              if (loading) return (
                <View style={style.noExrMsgContainer}>
                  <Text style={style.noExrMsg}>Loading...</Text>
                </View>
              )
              return (
                <View style={style.exerciseContainer}>
                  {data.exercises.length === 0
                    ? <div key="0" className="noMatch">
                      Sorry, we cannot find anything that matches these conditions.
                    </div>
                    : data.exercises.map((exercise, index) => (
                      <ExerciseListItem
                        key={exercise.id}
                        index={index}
                        exercise={exercise}
                        inWorkout={
                          find(props.workout.exercises, ['name', exercise.name])
                        }
                        isSelected={find(selectedExercises, exercise)}
                        selectExercise={selectExercise}
                      />
                    ))
                  }
                  {data.exercises.length % first === 0 &&
                  <Button
                    variant="contained"
                    style={styles.moreExrBtn} color="primary" key="X"
                    onClick={() => {
                      setPage(page + 1);
                      fetchMore({
                        variables: {
                          ...filterConditions,
                          first,
                          skip: first * page
                        },
                        updateQuery: (prevResult, {fetchMoreResult}) => {
                          return {
                            exercises: [
                              ...prevResult.exercises, ...fetchMoreResult.exercises
                            ]
                          }
                        }
                      })
                    }}
                  >
                    Load more...
                  </Button>
                  }
                </View>
              )
            }}
          </Query>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.closeModal}>Cancel</Button>
        <Button
          variant="text"
          color="primary"
          onClick={addExerciseAndClose}
          disabled={selectedExercises.length < 1}
        >
          Add Exercises
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

export default ExercisePicker
